html {
  width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #295c88;
}

.builder-component {
  width: 100%;
}

.Toastify__toast--success {
  background-color: #295c88 !important;
  color: white !important;
}
.Toastify__toast--error {
  background-color: #ff4040 !important;
  color: white !important;
}
.Toastify__toast--info {
  background-color: #00b8e6 !important;
  color: white !important;
}
.Toastify__toast--warning {
  background-color: #ffaa15 !important;
  color: white !important;
}

.builder-text {
  hyphens: auto;
}

.stepper-item-inner {
  width: 100% !important;
  height: 100% !important;
}

.stepper-title {
  max-width: 100px !important;
  min-width: 80px !important;
}

.stepper-item-inner-completed {
  background-color: #007cc3 !important;
}

.stepper-container {
  margin-bottom: 45px;
}
